import {AbstractControl } from '@angular/forms';


export const CustomValidateEmail = (control: AbstractControl): {[key: string]: any} => {
  // tslint:disable-next-line:max-line-length
  const regExp = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

  if (!control.value) {
    return null;
  }

  if (!regExp.test(control.value)) {
    return {
      invalidEmail: true,
      message: 'It is not a valid email'
    };
  }

  return null;
};
