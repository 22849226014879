import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { constant } from '../app.constants';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptService implements HttpInterceptor {

  constructor(private router: Router) { }

  // intercept request and add headers
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone();

    if (!request.headers.get('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json'
        }
      });
    }

    if (!request.headers.get('Authorization')) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${constant.auth.getToken()}`
        }
      });
    }


    return next.handle(request)
    .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            if (!constant.production && constant.debug) {
              console.log(`%cSTART HttpRequest :: Method => ${request.method} :: URL => ${request.url} :: `, 'color: green;');
              console.log(`%cHttpResponse`, 'color: green;', event);
              console.log(`%cEND HttpRequest :: Method => ${request.method} :: URL => ${request.url} :: `, 'color: green;');
            }
          }
        }, error => {
          if (!constant.production && constant.debug) {
            console.log(`%cSTART HttpRequest :: Method => ${request.method} :: URL => ${request.url} :: `, 'color: red;');
            console.error(`%cHttpResponse`, 'color: red;', error);
            console.log(`%cEND HttpRequest :: Method => ${request.method} :: URL => ${request.url} :: `, 'color: red;');
          }
          if (error.status === 401) {
            localStorage.setItem(constant.auth.keys.urlBeforExpelling, this.router.url);
            localStorage.removeItem(constant.auth.keys.token);
            this.router.navigateByUrl('');
          }
        })
      );
  }

}
