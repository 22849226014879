import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { Purchase } from '../../purchase';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { PurchasesService } from 'src/app/services/purchases/purchases.service';
import { pureArrayDef } from '@angular/core/src/view';
import { finalize } from 'rxjs/internal/operators/finalize';
import { ToastrService } from 'ngx-toastr';
import { IMyDateModel } from 'mydatepicker';
import { INgxMyDpOptions } from 'ngx-mydatepicker';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-purchase-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit, OnChanges {

  public form: FormGroup;

  myOptions: INgxMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
  };
  @Input() purchase: Purchase;
  @Input() allPurchases = [];
  @Output() closep: EventEmitter <boolean> = new EventEmitter <boolean> ();

  constructor (
    private _purchase: PurchasesService,
    private _toast: ToastrService,
    private fr: FormBuilder

  ) {
    this.form = this.fr.group({
      dateOfService: ['', [CustomValidators.required]],
      receipt: ['', [CustomValidators.required]],
      deductions: ['', [CustomValidators.required]],
      amount: ['', [CustomValidators.required]],
      name: ['', [CustomValidators.required]],
    });
  }


  ngOnInit() {
    this.valueChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['purchase']) {
      console.log('info select', this.purchase);
      this.form.patchValue({
        dateOfService: this.purchase.dateOfService,
        receipt: this.purchase.receipt,
        deductions: this.purchase.deductions,
        amount: this.purchase.amount,
        name: this.purchase.customer.name + ' ' + this.purchase.customer.last_name,
      });
    }
  }

  updateEdit(value) {
    this._purchase
      .update(this.form.value, this.purchase.id)
      .pipe(
        finalize(() => {
          this.form.reset();
          this.closeUpdate();
        }))
      .subscribe((successfull) => {
        console.log('Ok', successfull);
        this._toast.success('Success Ok');
      }, (error) => {
        console.log('Error', error);
        this._toast.error('error');
      });

  }

  closeUpdate() {
    // metodo para emitir al padre y cerrar la card
    this.closep.emit(false);
  }


  onDateChanged($event: IMyDateModel) {
    this.form.patchValue({
      dateOfService: $event.formatted,
    });
  }

  private valueChanges() {
    this.form.get('receipt').valueChanges.subscribe(val => {
      if (val !== null) {
        if (this.validateReceipt(false, val)) {
          this.validateReceipt(true, val);
        }
      }
    });
  }

  validateReceipt(msj, value?) {
    const receipt = value ? value : this.form.get('receipt').value;
    msj ? this.swal('warning', null, 'There is already a purchase with this receipt') : null;
    return !!this.allPurchases.filter( item => item.receipt == receipt).length;
  }

  private swal(type: string, resp?: string, msj?: string) {
    if (type == 'warning') {
      Swal({
        position: 'top-end',
        title: msj ? msj : resp,
        type: 'warning',
        showConfirmButton: false,
        timer: 3000
      });
    }
  }
}
