import {ValidatorFn, AbstractControl } from '@angular/forms';
import { isMultiParams } from 'src/app/utils/helpers/helpers';

export const CustomValidateRequiredLocation = (options: any): ValidatorFn => {

  const message = 'This value must contain parameters';


  return (control: AbstractControl): any => {

    try {
      let attributes = [];
      let valid = true;

      if (!control.value) {
        return null;
      }

      if (typeof options === 'string') {
        attributes = isMultiParams(options);
      }

      if (typeof control.value === 'object') {

        attributes.forEach(element => {
          if (!control.value[element]) {
            valid = false;
          } else {
            if (!control.value[element].toString().length) {
              valid = false;
            }
          }
        });

        if (valid) {
          return null;
        }
      }

      return {
        invalidRequiredLocation: true,
        message: `${message} "${options}"`
      };
    } catch (error) {
      console.log(error);
      return {
        invalidValidation: true,
        message: `Error validating the field`
      };
    }


  };
};
