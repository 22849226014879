import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { HospitalsService } from 'src/app/services/hospitals/hospitals.service';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';

declare const moment: any;

@Component({
  selector: 'app-private-customers-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit, OnDestroy {

  data: any;
  hospitals = [];
  wait: boolean;
  hospital = '';
  selected;
  allSubscriptions = [];

  constructor(
    private _toastr: ToastrService,
    private _customers: CustomersService,
    private _hospitals: HospitalsService
  ) { }

  ngOnInit() {
    this.getHospitals();
  }

  /**
   * @description Cargar hospitales
   */
  getHospitals() {
    return this._hospitals
      .get()
      .subscribe(
        (success) => {
          this.hospitals = success.data;
        }, (error) => {
          console.error('getHospitals error', error);
          this._toastr.error(error.message);
        }
      );
  }

  onImportXls(event) {
    console.log(event);
    // event.json.forEach(element => {
    //   element.import = true;
    // });
    this.data = event;
  }

  onErrorsImport(data) {
    console.log('onErrorsImport', data);
    this.data.errors = [];
    data.forEach(element => {
      element.import = true;
      element.success = false;

      this.data.errors.push(element);
    });
  }

  submit() {
    this.wait = true;
    this._customers
      .import(this.data.json, this.hospital)
      .pipe(
        finalize(() => {
          this.wait = false;
        })
      )
      .subscribe(
        (success) => {
          console.log('submit success', success);
          if (success.data.errors.length) {
            this.onErrorsImport(success.data.errors);
          } else {
            Swal({
              title: 'Successful Import',
              type: 'success',
              confirmButtonColor: '#3085d6 ',
              confirmButtonText: 'Close'
            });
          }
        }, (err) => {
          console.error('onSubmit error', err);
        }
      );

    // if (this.hospital) {
    //   const data = this.data.json.filter((element) => {
    //     if (element.import === true && !element.success) {
    //       return element;
    //     }
    //   });

    //   data.forEach(element => {
    //     this.send(element);
    //   });

    // } else {
    //   this._toastr.warning('You must select a hospital', 'Required');
    // }

  }

  send(data, retry?) {
    data.send = true;
    data.onImport = true;
    const subscription = this._customers
      .create({
        employee_id: data.employee,
        name: data.firstname,
        last_name: data.lastname,
        address: data.striaddressng,
        zipcode: data.zipcode,
        phone: data.phone,
        cell_phone: data.cell_phone,
        email: data.email,
        hospital: this.hospital,
        apt: data.apt,
        city: data.city,
        state: data.state,
      })
      .pipe(
        finalize(() => {
          data.onImport = false;
        })
      )
      .subscribe(
        (success) => {
          data.success = true;
        }, (err) => {
          console.error('onSubmit error', err);
          data.success = false;
          data.errors = err.message;
        }
      );

    this.allSubscriptions.push(subscription);
  }

  viewMesagesError(data) {
    this.selected = data;
  }

  ngOnDestroy( ): void {
    this.allSubscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }


}
