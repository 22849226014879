import {ValidatorFn, AbstractControl } from '@angular/forms';

export const CustomValidateInArray = (options: any): ValidatorFn => {
  const message = 'The value is invalid';
  const toStr = [];

  return (control: AbstractControl): any => {

    if (typeof options === 'string') {
      options = options.split(',');
    }

    if (!control.value) {
      return null;
    }

    options.forEach(element => {
      toStr.push(element.toString());
    });

    return toStr.indexOf(control.value.toString()) !== -1 ? null : {
      invalidInArray: true,
      message: message
    };
  };

};
