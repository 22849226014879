import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TemplateComponent } from '../../template/template.component';
import { AuthGuard } from '../../guards/auth/auth.guard';
import { ImportsComponent } from './components/imports/imports.component';
import { ExportsComponent } from './components/exports/exports.component';
import {ReportComponent} from './components/report/report.component';

const routes: Routes = [{
  path: 'private/data-processing',
  component: TemplateComponent,
  canActivate: [AuthGuard],
  children: [{
    path: 'imports',
    component: ImportsComponent
  }, {
    path: 'exports',
    component: ExportsComponent
  }, {
    path: 'report',
    component: ReportComponent
  }]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataProcessingRoutingModule { }
