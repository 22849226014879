import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgxPaginationModule } from 'ngx-pagination';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { ProgressBarModule } from 'src/app/utils/progress-bar/progress-bar.module';
import { OrderModule } from 'ngx-order-pipe';
import { DropDowndsModule } from 'src/app/utils/drop-downds/drop-downds.module';

@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule,
    FormsModule,
    FilterPipeModule,
    ProgressBarModule,
    OrderModule,
    DropDowndsModule,
    NgxPaginationModule
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
