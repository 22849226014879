import {ValidatorFn, AbstractControl } from '@angular/forms';

export const CustomLength = (length: any): ValidatorFn => {
  const message = `This field only supports ${length} characters`;

  return (control: AbstractControl): any => {

    if (!control.value) {
      return null;
    }

    return parseInt(control.value.length, 10) === parseInt(length, 10) ? null : {
      invalidLength: true,
      message: message
    };
  };

};
