import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { Observable } from 'rxjs';
import { constant } from 'src/app/app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private _global: GlobalService, private http: HttpClient) {}

  getType(): Observable < any > {

    return this._global.get(constant.transaction.urls.base);
  }

  getHistoryTransactions(employee: number): Observable < any > {
    return this._global.get(`${constant.balance.urls.history}/${employee}`);
  }

  getPendingTransactions(employee: number): Observable < any > {
    return this._global.get(`${constant.balance.urls.pending}/${employee}`);
  }

  importDeductions(deduction: any): Observable<any> {
    return this._global.post(`${constant.balance.urls.deductions}`, deduction);
  }

  exportBalances(hospital: any): Observable<any> {
    return this._global.get(`${constant.balance.urls.exportBalance}${hospital}`, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    });
  }
}
