import {AbstractControl } from '@angular/forms';


export const CustomOnlyText = (control: AbstractControl): {[key: string]: any} => {
  const regExp = new RegExp(/^[aA-zZ áéíóúÁÉÍÓÚñÑ]*$/);

  if (!control.value) {
    return null;
  }

  if (!regExp.test(control.value)) {
    return {
      invalidOnlyText: true,
      message: 'Only letters are allowed'
    };
  }

  return null;
};
