import { Component, OnInit } from '@angular/core';
import { HospitalsService } from 'src/app/services/hospitals/hospitals.service';
import { ToastrService } from 'ngx-toastr';
import { TransactionService } from 'src/app/services/transactions/transaction.service';

@Component({
  selector: 'app-private-data-processins-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss']
})
export class ExportsComponent implements OnInit {

  hospital = '';
  hospitals = [];

  constructor(
    private _toastr: ToastrService,
    private _hospitals: HospitalsService,
    private _transactions: TransactionService,
  ) { }

  ngOnInit() {
    this.getHospitals();
  }

  /**
   * @description Cargar hospitales
   */
  getHospitals() {
    return this._hospitals
      .get()
      .subscribe(
        (success) => {
          this.hospitals = success.data;
        }, (error) => {
          console.error('getHospitals error', error);
          this._toastr.error(error.message);
        }
      );
  }

  /**
   * @description Cargar hospitales
   */
  export() {
    return this._transactions
      .exportBalances(this.hospital)
      .subscribe(
        (success) => {
          console.log('export success', success );

          const downloadUrl = URL.createObjectURL(success);
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = 'file.csv';
          document.body.appendChild(a);
          a.click();

        }, (error) => {
          console.error('export error', error);
          this._toastr.error(error.message);
        }
      );
  }

}
