import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, } from '@angular/forms';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { HospitalsService } from 'src/app/services/hospitals/hospitals.service';
import { ToastrService } from 'ngx-toastr';
import { Hospital } from './hospital';
import Swal from 'sweetalert2';
import {swalDefaultsProvider} from '@toverux/ngx-sweetalert2/di';


@Component({
  selector: 'app-private-hospitals-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, OnChanges {

  @Input() cliHospital: Hospital;
  @Output() onsave: EventEmitter < any > = new EventEmitter();
  @Output() closeEmitter: EventEmitter < boolean > = new EventEmitter < boolean > ();

  public form: FormGroup;
  isupDate: boolean; /*Variable para establecer metodo segun los cambios en el onSubmit*/
  current: number; /*Variable para el id a utilizar en el upDate */
  action: boolean; /*Variable para destruir la modal  */


  constructor(
    private _hospitals: HospitalsService,
    private fr: FormBuilder,
    private _toastH: ToastrService
  ) {
    this.builderForm();
  }

  ngOnInit() {}

  builderForm() {
    this.form = this.fr.group({
      active: [''],
      name: ['', [CustomValidators.required]],
      short_name: ['', [CustomValidators.required]],
      speed_code: ['', [CustomValidators.required]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isupDate = false; /*se le da el valor false cuando se activa ngOnChanges*/
    this.form.reset();
    this.current = undefined;
    if (changes['cliHospital']) {
      if (changes['cliHospital'].currentValue) {
        this.isupDate = true; /*se le da el valor true cuando hay canvios en cliHospital*/
        this.current = changes['cliHospital'].currentValue.id;

        this.form.setValue({
          name: changes['cliHospital'].currentValue.name,
          short_name: changes['cliHospital'].currentValue.short_name,
          speed_code: changes['cliHospital'].currentValue.speed_code,
          active: changes['cliHospital'].currentValue.active
        });
      }
    }
  }

  onSubmit(value) {

    console.log(this.form);
    if (this.isupDate === false) {
      value.active = true;
      this._hospitals.create(value).subscribe(
        (successFull) => {
          Swal({
            position: 'top-end',
            type: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500
          });
          this.form.reset();
          this.onsave.emit(true);
        },
        (error) => {
          console.log('Submit', error);
          Swal({
            type: 'error',
            title: 'Oops...',
            text: (error.message)
          });
        });
    }

    if (this.isupDate === true) {
      this._hospitals.update(value, this.current).subscribe(
        (successFull) => {
          console.log(successFull);
          Swal({
            position: 'center',
            type: 'success',
            title: 'The hospital has been updated',
            showConfirmButton: false,
            timer: 1500
          });
          this.onsave.emit(true);
          this.closeEmitter.emit(false);
        },
        (error) => {
          Swal('Update', error);
          this._toastH.error('error ');
        });
    }
  }

  clickOnClose() {
    this.closeEmitter.emit(false);
  }
}
