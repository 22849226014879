import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges {

  public styles = {};

  @Input() determinate: boolean;
  @Input() progress: any;
  @Input() color = 'primary';
  @Input() spinner: boolean;
  @Input() spinnerWidth: number;

  constructor() { }

  ngOnInit() {
    if (this.spinnerWidth) {
      this.styles = {
        'width': `${this.spinnerWidth}px`,
        'height': `${this.spinnerWidth}px`
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['progress']) {
      this.progress = changes['progress'].currentValue;
    }
  }

}
