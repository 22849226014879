export class Hospital {


     id: any;
     user_id: any;
     name: any;
     short_name: any;
     speed_code: any;
     active: any;







}
