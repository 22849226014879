export function isPresent(obj: any): boolean {
  return obj !== undefined && obj !== null;
}

export function isMultiParams(str: string): any {
  const array = str.split(',');
  if (array.length > 1) {
    return array;
  } else {
    return false;
  }
}

export function isDate(value: any): boolean {
  return value instanceof Date && !isNaN(value.valueOf());
  // return !/Invalid|NaN/.test(new Date(obj).toString());
}


export function normalize(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  const to   = 'aaaaaeeeeeiiiiooooouuuunc------';
  for (let i = 0, l = from.length ; i < l; i++ ) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '_') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

// export function isDate