import { CustomValidateUrl } from './components/urls/url.validator';
import { CustomValidateEquals } from './components/equals/equals.validator';
import { CustomValidateInArray } from './components/in-arrray/in-array.validator';
import { CustomOnlyText } from './components/only-text/only-text.validator';
import { CustomOnlyNumbers } from './components/only-numbers/only-numbers.validator';
import { CustomLength } from './components/length/length.validator';
import { CustomRequired } from './components/required/required-validator';
import { CustomMinLength } from './components/min-length/min-length.validator';
import { CustomMaxLength } from './components/max-length/max-length.validator';
import { CustomValidateRequiredLocation } from './components/required-location/required-location.validator';
import { CustomValidateEmail } from './components/email/email.validator';
import { CustomValidateDate } from './components/date/date.validator';


export const CustomValidators = {
  _name: 'CustomValidators',
  _nameSplit: 'CustomValidators.',
  _isProperty: 'Property.',
  url: CustomValidateUrl,
  equals: CustomValidateEquals,
  inArray: CustomValidateInArray,
  required: CustomRequired,
  onlyText: CustomOnlyText,
  onlyNumbers: CustomOnlyNumbers,
  length: CustomLength,
  minLength: CustomMinLength,
  maxLength: CustomMaxLength,
  requiredLocation: CustomValidateRequiredLocation,
  email: CustomValidateEmail,
  date: CustomValidateDate
};
