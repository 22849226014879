import { AbstractControl } from '@angular/forms';
import { isDate } from 'src/app/utils/helpers/helpers';


export const CustomValidateDate = (control: AbstractControl): {[key: string]: any} => {

  if (!control.value) {
    return null;
  }
  if (!isDate(control.value)) {
    return {
      invalidDate: true,
      message: 'It is not a valid date'
    };
  }

  return null;
};
