import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataProcessingRoutingModule } from './data-processing-routing.module';
import { ImportsComponent } from './components/imports/imports.component';
import { ToImportModule } from 'src/app/utils/to-import/to-import.module';
import { FormsModule } from '@angular/forms';
import { ProgressBarModule } from 'src/app/utils/progress-bar/progress-bar.module';
import { CustomersModule } from '../customers/customers.module';
import { TransactionService } from 'src/app/services/transactions/transaction.service';
import { ExportsComponent } from './components/exports/exports.component';
import { ReportComponent } from './components/report/report.component';
import {AgGridModule} from 'ag-grid-angular';
import {PurchasesModule} from '../purchases/purchases.module';

@NgModule({
  declarations: [ImportsComponent, ExportsComponent, ReportComponent],
  imports: [
    CommonModule,
    DataProcessingRoutingModule,
    ToImportModule,
    FormsModule,
    ProgressBarModule,
    CustomersModule,
    AgGridModule,
    PurchasesModule
  ],
  providers: [TransactionService]
})
export class DataProcessingModule { }
