import {AbstractControl } from '@angular/forms';


export const CustomOnlyNumbers = (control: AbstractControl): {[key: string]: any} => {
  const regExp = new RegExp(/^[0-9]*$/);

  if (!control.value) {
    return null;
  }

  if (!regExp.test(control.value)) {
    return {
      invalidOnlyNumbers: true,
      message: 'Only allow numbers'
    };
  }

  return null;
};
