import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchasesRoutingModule } from './purchases-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardModule } from 'src/app/utils/card/card.module';
import { CreateComponent } from './components/create/create.component';
import { PurchasesComponent } from './purchases.component';
import { PurchasesService } from 'src/app/services/purchases/purchases.service';
import { DeleteComponent } from './components/delete/delete.component';
import { MyDatePickerModule } from 'mydatepicker';
import { DropDowndsModule } from 'src/app/utils/drop-downds/drop-downds.module';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';
import { ProgressBarModule } from 'src/app/utils/progress-bar/progress-bar.module';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { UpdateComponent } from './components/update/update.component';
import { ImportComponent } from './components/import/import.component';
import {ToImportModule} from '../../../utils/to-import/to-import.module';

@NgModule({
  declarations: [CreateComponent, PurchasesComponent, DeleteComponent, UpdateComponent, ImportComponent],
  imports: [
    CommonModule,
    PurchasesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    MyDatePickerModule,
    NgxMyDatePickerModule,
    DropDowndsModule,
    ProgressBarModule,
    SweetAlert2Module,
    NgxMaskModule.forRoot(),
    NgxPaginationModule,
    ToImportModule
  ],
  exports: [
    ImportComponent
  ],
  providers: [PurchasesService]
})

export class PurchasesModule { }
