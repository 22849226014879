import { Component, OnInit } from '@angular/core';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  show: boolean;
  public users = [];
  public search: String;

  constructor(
    private _customers: CustomersService,
    private _toast: ToastrService
    ) { }

  ngOnInit() {

  }

  getAllCustomers() {
    this.show = true;
    return this._customers
      .get()
      .pipe(
        finalize(() => {
          this.show = false;
        })
      )
      .subscribe(
        (success) => {
          console.log(success);
          this.users = success.data;
        }, (error) => {
          console.log('getAllCustomers error', error);
          this._toast.error(error.message);
        }
      );
  }

  filter() {


  }

}
