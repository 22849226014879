import { Component, OnInit } from '@angular/core';
import {finalize} from 'rxjs/operators';
import {PurchasesService} from '../../../../../services/purchases/purchases.service';

declare const moment: any;

@Component({
  selector: 'app-purchases-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {
  private data: any;

  constructor(
    private _purchases: PurchasesService
  ) { }

  ngOnInit() {
  }

  onImportXls(event) {
    event.json.forEach(element => {
      element.import = true;
    });
    this.data = event;
    console.log('onImportXls', this.data);
  }

  submit() {
    this.data.json.filter((element) => {
      if (element.import === true) {
        this.send(element);
      }
    });
  }

  send(data: any, retry?: boolean) {
    data.send = true;
    data.onImport = true;
    console.log('send', data);

    this._purchases.importPurchases([{
      amount:        data.amount,
      dateOfService: data.date_of_sale,
      deductions:    data.deductions,
      employee_id:   data.employee_id,
      receipt:       data.ticket_nbr,
    }])
      .pipe(
        finalize(() => {
          data.onImport = false;
      })).subscribe(resp => {
        console.log('resp', resp);
        if (resp) {
          if (resp[0]) {
            data.success = resp[0].resp;
            data.msj = resp[0].msj;
          }
        }

    });
  }

}
