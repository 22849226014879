import { Component, OnInit } from '@angular/core';
import { TransactionService } from 'src/app/services/transactions/transaction.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-private-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.scss']
})
export class ImportsComponent implements OnInit {
  tab = 'Customers';
  data: any;

  constructor(
    private _transactions: TransactionService,
    private _toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  onImportXls(event) {
    event.json.forEach(element => {
      element.import = true;
    });
    this.data = event;
    console.log('onImportXls', this.data);
  }

  submit() {
    this.data.json.filter((element) => {
      if (element.import === true) {
        this.send(element);
      }
    });
  }

  send(data: any, retry?: boolean) {
    data.send = true;
    data.onImport = true;

    this.
      _transactions
      .importDeductions([{
        amount: data.cur_dedn,
        employeeId: data.employee_no,
      }])
      .pipe(
        finalize(() => {
          data.onImport = false;
        })
      )
      .subscribe(
        (success) => {
          console.log('success', success);
          if (success) {
            if (success[0]) {
              data.success = success[0].success;
            }
          }
        },
        (error) => {
          this._toastr.error(error.message);
          data.success = false;
        });

    // setTimeout(() => {
    //   data.onImport = false;
    //   data.success = retry ? true : Math.random() >= 0.5;
    // }, 1500 );
  }

}
