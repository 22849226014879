import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersComponent } from './components/customers/customers.component';
import { AgGridModule } from 'ag-grid-angular';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { CardModule } from '../card/card.module';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';

@NgModule({
  declarations: [CustomersComponent],
  imports: [
    CommonModule,
    AgGridModule,
    CardModule,
    ProgressBarModule
  ],
  exports: [CustomersComponent],
  providers: [CustomersService]
})
export class DropDowndsModule { }
