import { Component, OnInit } from '@angular/core';
import { constant } from 'src/app/app.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-private-template-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  logout() {
    localStorage.removeItem(constant.auth.keys.token);
    this.router.navigateByUrl('');
  }

}
