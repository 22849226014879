import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TemplateComponent } from '../../template/template.component';
import { AuthGuard } from '../../guards/auth/auth.guard';
import { CustomersComponent } from './customers.component';
import { DetailComponent } from './components/detail/detail.component';

const routes: Routes = [{
  path: 'private',
  component: TemplateComponent,
  canActivate: [AuthGuard],
  children: [{
    path: 'customers',
    component: CustomersComponent
  }, {
    path: 'customers/detail',
    component: DetailComponent
  }]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersRoutingModule { }
