import { AbstractControl } from '@angular/forms';

export const CustomRequired = (control: AbstractControl): {[key: string]: any} => {

  return control.value ? null : {
    invalidRequired: true,
    message: 'This value is required'
  };

};
