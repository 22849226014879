import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { Observable } from 'rxjs';
import { constant } from 'src/app/app.constants';
import { CustomersInterface } from 'src/app/private/modules/customers/customers-interface';
import { Form, FormGroup } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class HospitalsService {

  constructor(private _global: GlobalService) {}

  /**
   * @description Cargar todos los Clientes
   * @returns Observable <any>
   */
  get(): Observable<any> {
    return this._global
      .get(constant.hospitals.urls.base);
  }
  create(data) {
    return this._global.post(constant.hospitals.urls.base, data);
  }

  update(data, id): Observable<any> {
    return this._global
      .put(`${constant.hospitals.urls.base}/${id}`, data);
  }

  delete(hospital): Observable<any> {
    return this._global
      .delete(`${constant.hospitals.urls.base}/${hospital}`);
  }

}
