import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { CustomersInterface } from 'src/app/private/modules/customers/customers-interface';
import { Observable } from 'rxjs';
import { constant } from 'src/app/app.constants';

@Injectable({
  providedIn: null
})
export class CustomersService {

  constructor(private _global: GlobalService) {}

  /**
   * @description Crear nuevo Cliente
   * @returns Observable <any>
   */
  create(data: CustomersInterface): Observable<any> {
    return this._global
      .post(constant.customers.urls.profiles, data);
  }

  /**
   * @description Creacion masiva de cliente
   */
  import(data, hospital): Observable<any> {
    return this._global
      .post(constant.customers.urls.import, {
        customers: data,
        hospital: hospital
      });
  }


  /**
   * @description Cargar todos los Clientes
   * @returns Observable <any>
   */
  get(): Observable<any> {
    return this._global
      .get(constant.customers.urls.profiles);
  }

  /**
   * @description Cargar detalle de un cliente
   * @returns Observable <any>
   */
  getDetail(employee): Observable<any> {
    return this._global
      .get(`${constant.customers.urls.profiles}/${employee}`);
  }

  /**
   * @description Editar Cliente
   * @returns Observable <any>
   */
  update(data: CustomersInterface, id: number): Observable<any> {
    return this._global
      .put(`${constant.customers.urls.profiles}/${id}`, data);
  }

}
