import {ValidatorFn, AbstractControl } from '@angular/forms';

export const CustomMinLength = (minLength: any): ValidatorFn => {
  const message = `This field must have at least ${minLength} characters`;

  return (control: AbstractControl): any => {

    if (!control.value) {
      return null;
    }

    return parseInt(control.value.length, 10) >= parseInt(minLength, 10) ? null : {
      invalidMinLength: true,
      message: message
    };
  };

};
