import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TemplateComponent } from '../../template/template.component';
import { AuthGuard } from '../../guards/auth/auth.guard';
import { TransactionsComponent } from './transactions.component';


const routes: Routes = [{
    path: 'private/transactions',
    component: TemplateComponent,
    canActivate: [AuthGuard],
    children: [{
      path: '',
      component: TransactionsComponent
    }]

}];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class TransactionsRoutingModule { }
