import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LoginInterface } from './login-interface';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { constant } from 'src/app/app.constants';

@Component({
  selector: 'app-public-authentication-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  onLogin: boolean;

  login: LoginInterface = {
    username: '',
    password: ''
  };

  constructor(
    private _auth: AuthenticationService,
    private _toastr: ToastrService,
    private router: Router
  ) {

  }

  ngOnInit() {}

  /**
   * @description Solicitud de login
   */
  onSubmit(event) {
    this.onLogin = true;
    return this._auth
      .login(this.login)
      .pipe(
        finalize(() => {
          this.onLogin = false;
        })
      )
      .subscribe(
        (success) => {
          localStorage.setItem(constant.auth.keys.token, success.access_token);
          this._toastr.success('Successful', 'Login');
          const urlBeforExpelling = localStorage.getItem(constant.auth.keys.urlBeforExpelling);
          localStorage.removeItem(constant.auth.keys.urlBeforExpelling);
          this.router.navigateByUrl(
            urlBeforExpelling ? urlBeforExpelling.length > 2 ? urlBeforExpelling  : constant.views.home.base : constant.views.home.base
          );
        }, (error) => {
          console.log('onSubmit error', error);
          this._toastr.error(error.message);
        }
      );
  }


}
