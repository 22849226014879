import { Component, OnInit, } from '@angular/core';
import { Hospital } from './components/create/hospital';
import { HospitalsService } from 'src/app/services/hospitals/hospitals.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-hospitals',
  templateUrl: './hospitals.component.html',
  styleUrls: ['./hospitals.component.scss']
})
export class HospitalsComponent implements OnInit {

  hp = [];
  cliHp: Hospital;

  constructor(
    private _hospitals: HospitalsService,
    private _toast: ToastrService
    ) {}

  ngOnInit() {
    this.getHospitals();
  }

  getHospitals() {

    this._hospitals.get().subscribe(
      (successfull) => {
        console.log('get Hospital', successfull);
        this.hp = successfull.data;
      },
      (error) => {
        console.log(error);
      });
  }

  cliHospital(ohp: Hospital): void {
    this.cliHp = ohp;
  }

  deleteHospital(hospital) {
    this._hospitals
    .delete(hospital.id)
    .subscribe(
      (successfull) => {
        console.log('deleteHospital', successfull);
        Swal({
          position: 'center',
          type: 'success',
          title: 'Success!',
          showConfirmButton: false,
          timer: 1500
        });
        this.hp.splice(this.hp.indexOf(hospital), 1);
        this.getHospitals();
      },
      (error) => {
        Swal({
          type: 'error',
          title: 'Oops...',
          text: (error.message),
        });
        console.log('deleteHospital', error);
      });
  }
}
