import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { INgxMyDpOptions } from 'ngx-mydatepicker';
import { UsersService } from 'src/app/services/users/users.service';
import { UsersInterface } from '../../users-interface';

declare const moment: any;

@Component({
  selector: 'app-private-users-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, OnChanges {

  form: FormGroup;

  myOptions: INgxMyDpOptions = {
    dateFormat: 'mm-dd-yyyy',
  };

  onSave: boolean;
  isUpdate: boolean;

  @Input() user: any;

  @Output() onsuccess: EventEmitter<any> = new EventEmitter();
  @Output() onupdate: EventEmitter<any> = new EventEmitter();
  @Output() onerror: EventEmitter<any> = new EventEmitter();
  @Output() closeEmitter: EventEmitter <boolean> = new EventEmitter<boolean>();

  constructor(
    private fr: FormBuilder,
    private _toastr: ToastrService,
    private _users: UsersService
  ) {
    this.builderForm();
  }

  ngOnInit() {
  }

  builderForm() {
    this.form = this.fr.group({
      name: ['', [CustomValidators.required]],
      last_name: ['', [CustomValidators.required]],
      email: ['', [CustomValidators.required]],
      date_of_birthay: [''],
      gender: [''],
      address: [''],
      zipcode: [''],
      phone: [''],
      cell_phone: [''],
    });
  }

  onSubmit(value) {
    const user  = this.form.getRawValue();
    user.date_of_birthay = user.date_of_birthay.formatted;
    if (!this.isUpdate) {
      return this.create(user);
    }

    user.date_of_birthay = user.date_of_birthay ? user.date_of_birthay : this.user.date_of_birthay;
    return this.update(user);
  }

  update(data: any) {
    data.profile = {...data};
    return this._users
    .update(data, this.user.id)
    .subscribe(
    (success) => {
      this._toastr.success(success.message);
      this.onupdate.emit(success);
    },
    (error) => {
      this._toastr.error(error.message);
      this.onerror.emit(error);
    });
  }

  create(user: UsersInterface) {
    return this._users
    .create(user)
    .subscribe(
    (success) => {
      this._toastr.success(success.message);
      this.onsuccess.emit(success);
      this.form.reset();
    },
    (error) => {
      this._toastr.error(error.message);
      console.log('success create', error);
      this.onerror.emit(error);
    });
  }

  clickOnClose() {
    this.closeEmitter.emit(false);
  }

  refreshForm(values) {
    const date = moment(values.profile ? values.profile.date_of_birthay : '');
    this.form.patchValue({
      name: values.name,
      email: values.email,
      last_name: values.profile ? values.profile.last_name : '',
      gender: values.profile ? values.profile.gender : '',
      address: values.profile ? values.profile.address : '',
      zipcode: values.profile ? values.profile.zipcode : '',
      phone: values.profile ? values.profile.phone : '',
      cell_phone: values.profile ? values.profile.cell_phone : '',
      date_of_birthay: date.isValid() ? {date: {
        year: date.toObject().years,
        month:  date.toObject().months + 1,
        day:  date.toObject().date
      }} : '',
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isUpdate = false;

    if (changes['user']) {
      if (changes['user'].currentValue) {
        if (changes['user'].currentValue.id) {
          this.isUpdate = true;
          this.refreshForm(changes['user'].currentValue);
        }
      }
    }
  }

}
