import {ValidatorFn, AbstractControl } from '@angular/forms';


export const CustomValidateUrl = (control: AbstractControl): {[key: string]: any} => {
  const regExp = new RegExp(/^(http|ftp|https):\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]/);

  if (!control.value) {
    return null;
  }

  if (!regExp.test(control.value)) {
    return {
      invalidUrl: true,
      message: 'It is not a valid url'
    };
  }

  return null;
};
