import { environment } from '../environments/environment';

export const constant = {
  production: environment.production,
  debug: false,

  // Authentication
  auth: {
    getToken: () => {
      return localStorage.getItem('token');
    },
    getUser: () => {
      return JSON.parse(localStorage.getItem('user'));
    },
    client_id: '3',
    client_secret: 'firreQeWDRlDWUxjnGksdm1PDbEoxu17NPbbHgke',
    keys: {
      token: 'token',
      userData: 'user',
      urlBeforExpelling: 'urlBeforExpelling'
    },
    urls: {
      base: environment.apiRest.base + 'oauth',
      login: environment.apiRest.base + 'oauth/token',
      verifyToken: environment.apiRest.base + 'oauth/personal-access-tokens',
    },
  },

  // Customers
  customers: {
    urls: {
      base: environment.apiRest.url + 'customers',
      import: environment.apiRest.url + 'customers/import',
      profiles: environment.apiRest.url + 'customers/profiles',
    },
  },

  // Hospitals
  hospitals: {
    urls: {
      base: environment.apiRest.url + 'hospitals'
    },
  },

  // Purchase
  pruchase: {
    urls: {
      base: environment.apiRest.url + 'purchases'
    },
  },

  purchases: {
    urls: {
      import: environment.apiRest.url + 'purchases/loadPurchases'
    },
  },

  // transaction
  transaction: {
    urls: {
      base: environment.apiRest.url + 'transaction_types'
    },
  },

  // Balance
  balance: {
    urls: {
      base: environment.apiRest.url + 'balances/purchasesTranslate',
      history: environment.apiRest.url + 'balances/custormer',
      pending: environment.apiRest.url + 'purchases/customer',
      deductions: environment.apiRest.url + 'balances/loadDeductions',
      exportBalance: environment.apiRest.url + 'balances/exportBalance/Hospital/',
      pendingBalance: environment.apiRest.url + 'balances/pendingBalances',
      postUpdateDeduction: environment.apiRest.url + 'balances/postUpdateDeduction'
    },
  },

  users: {
    urls: {
      base: environment.apiRest.url + 'users',
      actives: environment.apiRest.url + 'users/active',
      profiles: environment.apiRest.url + 'users/profiles',
    },
  },

  views: {
    login: {
      base: ''
    },
    home: {
      base: 'private/home',
    }
  }

};
