import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { constant } from 'src/app/app.constants';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {


  constructor(private router: Router) { }

  ngOnInit() {
  }

  onCloseAdd() {
    this.router.navigateByUrl(constant.views.home.base);
  }

}
