import { Component, OnInit } from '@angular/core';
import {GridOptions} from 'ag-grid-community';
import { BalanceService } from 'src/app/services/balance/balance.service';
import { HospitalsService } from 'src/app/services/hospitals/hospitals.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  protected id = 123;
  protected tab = 1;
  protected grid: GridOptions;
  protected selectHospitals = [];
  protected idHospital = '';
  private iHosp: number;
  private activateBtn = false;



  constructor(
    private _servicesBalance: BalanceService,
    private _servicesHospitals: HospitalsService
  ) {
    this.grid = <GridOptions> {};
    this.columnDefs();
    const self = this;
    this.grid.onGridReady = () => {
      self.grid.api.sizeColumnsToFit();
      self.grid.api.setRowData([]);
    };
  }

  ngOnInit() {
  // this.setData();
  this.getHospitals();

  }

  setData() {
    if (this.idHospital) {
      return this._servicesBalance.get(this.idHospital).subscribe(resp => {
        this.activateBtn = true;
        this.grid.api.setRowData(resp.data);
      });
    }
    Swal({
      type: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href>Why do I have this issue?</a>'
    });
    // alert('no hay id');
  }

  getHospitals() {
    return this._servicesHospitals
      .get()
      .subscribe(
        (success) => {
          console.log('selech', success);
          this.selectHospitals = success.data;
        }
      );
  }

  columnDefs() {
    this.grid.columnDefs = [
      {
        headerName: 'Hosp',
        field: 'short_name',
        width: 250
      },
      {
        headerName: 'Empl ID',
        field: 'employee_id',
        width: 250
      },
      {
        headerName: 'Last Name',
        field: 'last_name',
        width: 250
      },
      {
        headerName: 'First Name',
        field: 'name',
        width: 250
      },
      {
        headerName: 'Current Goal',
        field: 'balance',
        width: 250
      },
      {
        headerName: 'Amt To Deduct',
        field: 'amountDeduction',
        width: 250
      },
      {
        headerName: 'Deductions Nbr',
        field: 'deductions',
        width: 250
      },
      {
        headerName: 'New Goal',
        field: 'newGoal',
        width: 250
      },
      {
        headerName: 'New Deductions Nbr',
        field: 'newdeduct',
        width: 250
      },
    ];
  }

  exportExcel() {
    this.grid.api.selectAll();
    this.grid.api.exportDataAsExcel(this.paramsToExport_PR());
    this.grid.api.deselectAll();
  }

  private paramsToExport_PR() {
    this.selectHospitals.map((f, i) => {
      if (f.id == this.idHospital) {
        this.iHosp = i;
      }
    });
    console.log(this.iHosp);
    console.log(this.selectHospitals);
    return {
      skipHeader: false,
      columnGroups: true,
      skipFooters: true,
      skipGroups: false,
      allColumns: true,
      onlySelected: true,
      fileName: 'Balance-' + this.selectHospitals[this.iHosp].short_name
    };
  }

}
