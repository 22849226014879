import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { normalize, isDate } from 'src/app/utils/helpers/helpers';

declare const XLSX: any;
declare const moment: any;

@Component({
  selector: 'app-utils-to-import-xls',
  templateUrl: './xls.component.html',
  styleUrls: ['./xls.component.scss']
})
export class XlsComponent implements OnInit {

  data: any;
  event: any;
  json = [];
  onLoad: boolean;

  @Input() textBtn: string;
  @Input() icoBtn: string;
  @Input() showInfo = true;

  @ViewChild('inputFile') inputFile: ElementRef;

  @Output() emitterFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitterOnFileChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  normalizeKye (value) {
    return (typeof value === 'string') ? normalize(value) : value;
  }

  normalizeValue (value) {
    if (isDate(value)) {
      return moment(value).format('MM-DD-YYYY');
    }
    return (typeof value === 'string') ? value.trim() : value;
  }


  onFileChange(evt: any) {
    /* wire up file reader */
    this.onLoad = true;
    this.event = evt;
    const target: DataTransfer = <DataTransfer>(evt.target);
    this.emitterOnFileChange.emit(target);
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb = XLSX.read(bstr, {type: 'binary', cellDates: true, dateNF: 'dd-mm-yyyy;@'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* save data */
      this.data = (XLSX.utils.sheet_to_json(ws, {header: 1}));
      this.json = [];
      this.data.forEach((element, key) => {
        if (key > 0) {
          const tmp = {};
          this.data[key].forEach((value, k) => {
            tmp[this.normalizeKye(this.data[0][k])] = this.normalizeValue(value);
          });
          this.json.push(tmp);
        }
      });

      this.onLoad = false;
      this.emitter();
    };
    reader.readAsBinaryString(target.files[0]);
  }

  emitter() {
    this.emitterFile.emit({
      dataOriginal: this.data.length ? this.data : [],
      json: this.data.length ? this.json : [],
      event: this.event,
      keys:  this.data.length ? Object.keys(this.json[0]) : []
    });
  }

  uploadFile() {
    this.inputFile.nativeElement.click();
  }

}
