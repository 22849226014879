import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginInterface } from 'src/app/public/modules/authentication/components/login/login-interface';
import { constant } from 'src/app/app.constants';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: null
})
export class AuthenticationService {

  constructor(private _global: GlobalService) {}

    /**
   * @description realizar login
   * @returns Observable <any>
   */
  login(data: LoginInterface ): Observable<any> {
    return this._global
      .post(constant.auth.urls.login, {
        grant_type: 'password',
        client_id: constant.auth.client_id,
        client_secret: constant.auth.client_secret,
        username: data.username,
        password: data.password
      });
  }

  verifyToken() {
    return this._global
      .get(constant.auth.urls.verifyToken);
  }

  /**
   * logout
   */
  logout(): Observable<any> {
    return this._global.delete(
      constant.auth.urls.base
    );
  }

}
