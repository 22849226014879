import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { InlineSVGModule } from 'ng-inline-svg';
import { TemplateComponent } from './template.component';
import { HeaderComponent } from './components/header/header.component';
import { AsideComponent } from './components/aside/aside.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContentComponent } from './components/content/content.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    InlineSVGModule
  ],
  declarations: [
    TemplateComponent,
    HeaderComponent,
    AsideComponent,
    FooterComponent,
    ContentComponent
  ],
  providers: [
    AuthenticationService
  ]
})
export class TemplateModule { }
