import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FilterPipe } from 'ngx-filter-pipe';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

declare const faker: any;

@Component({
  selector: 'app-private-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  public search: string;
  public users = [];

  wait: boolean;

  constructor(
    private filterPipe: FilterPipe,
    protected _customers: CustomersService,
    private _toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.getAllCustomers();
  }

  getAllCustomers() {
    this.wait = true;
    return this._customers
      .get()
      .pipe(
        finalize(() => {
          this.wait = false;
        })
      )
      .subscribe(
        (success) => {
          console.log(success);
          this.users = success.data;
        }, (error) => {
          console.log('getAllCustomers error', error);
          this._toastr.error(error.message);
        }
      );
  }

  ngAfterViewInit() {
    // for (let i = 1; i <= 100; i++) {
    //   const randomCard = faker.helpers.userCard(); // userCard con muchas propiedades
    //   randomCard.id =  faker.random.number();
    //   this.users.push(randomCard);
    // }
  }

  filter() {
    if (this.search.length >= 3) {
      if (this.search === '*.*') {
        return this.users;
      }
      return this.filterPipe.transform(this.users, {
        $or: [
          { name: this.search },
          { id: this.search },
          { last_name: this.search },
          { employee_id: this.search }
        ]
      });
    }
  }

}
