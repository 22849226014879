import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { CardComponent } from 'src/app/utils/card/card.component';
import { TransactionService } from 'src/app/services/transactions/transaction.service';
import {BalanceService} from '../../../../../services/balance/balance.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-private-customers-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  customer;
  onLoading = true;
  transactions = [];
  pendingTransactions = [];
  historyTransactions = [];
  numDeduction = 0;
  activeSelect = false;
  deductionTransactions = [];
  balan = [];

  onLoadingHistory: boolean;
  onLoadingPendingTransactions: boolean;
  onLoadingDeductionTransactions: boolean;

  @ViewChild('modalUpdate') modalUpdate: CardComponent;

  constructor(
    private routeActive: ActivatedRoute,
    private _customers: CustomersService,
    private _toastr: ToastrService,
    private _transaction: TransactionService,
    private _balanceService: BalanceService
  ) {}

  ngOnInit() {
    this.routeActive.params
      .subscribe(params => {
        this.getCustomer(params.employee_id);
      });
  }

  /**
   * @desc Cargar datos del cliente
   * @param employee number
   */

  getHistoryTransactions(employee: number) {
    this.onLoadingHistory = true;
    return this._transaction
      .getHistoryTransactions(employee)
      .pipe(
        finalize(() => {
          this.onLoadingHistory = false;
          this.activeSelect = false;
        })
      )
      .subscribe(
        success => {
          console.log('getHistoryTransactions success', success);
          if (Array.isArray(success)) {
            this.historyTransactions = success;
            this.numDeduction = this.historyTransactions[0].deductions;
          }
        },
        error => {
          console.log('getTransactionCust error', error);
        }
      );
  }

  getPendingTransactions(employee: number) {
    this.onLoadingPendingTransactions = true;
    return this._transaction
      .getPendingTransactions(employee)
      .pipe(
        finalize(() => {
          this.onLoadingPendingTransactions = false;
        })
      )
      .subscribe(
        success => {
          console.log('getPendingTransactions success', success);
          if (Array.isArray(success)) {
            this.pendingTransactions = success;
          }
        },
        error => {
          console.log('getPendingTransactions error', error);
        }
      );
  }

  getDeductionTransactions(employee: number) {
    // this.onLoadingDeductionTransactions = true;
    // return this._transaction
    //   .getDeductionTransactios(employee)
    //   .pipe(finalize(() => {
    //     this.onLoadingDeductionTransactions = false;
    //   }))
    //   .subscribe(success => {
    //     console.log('getDeductions', success);
    //   }, error => {
    //     console.error(error);
    //   });
  }

  getCustomer(employee: number) {
    this._customers
      .getDetail(employee)
      .pipe(
        finalize(() => {
          this.onLoading = false;
        })
      )
      .subscribe(
        success => {
          this.getHistoryTransactions(success.data.id);
          this.getPendingTransactions(success.data.id);
          // this.getDeductionTransactions(success.data.id);
          this.customer = success.data;
        },
        err => {
          this._toastr.error(err.message);
          console.error(err);
        }
      );
  }

  /**
   * @description Actualizacion de datos
   */
  onUpdate(event) {
    try {
      if (event.save) {
        this.modalUpdate.close();
        Object.keys(event.data).forEach(key => {
          this.customer[key] = event.data[key];
        });
      }
    } catch (error) {
      this._toastr.error('An error occurred while loading the data to update', 'fn::onUpdate');
      console.error(error);
    }
  }

  updateDeduction() {
    const data = {
      'customer_id': this.customer.id,
      'deductions': this.numDeduction
    };
    Swal({
      // title: 'Are you sure?',
      text: 'Are you sure that want to change this value?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!'
    }).then((result) => {
      if (result.value) {
        this._balanceService.postUpdateDeduction(data)
          .pipe(
            finalize(() => {
              Swal({
                position: 'top-end',
                type: 'success',
                title: 'Has been update',
                showConfirmButton: false,
                timer: 1500
              });
            })
          )
          .subscribe(
          resp => {
            this.ngOnInit();
            console.log(resp);
        });

      }
    })
  }

}
