import { Component, OnInit, Input, ElementRef, EventEmitter, ViewChild, Output, AfterViewInit, ViewEncapsulation } from '@angular/core';

declare const jQuery: any;

@Component({
  selector: 'app-utils-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit, AfterViewInit {

  @Input() classStyle: string;
  @Input() title: string; // Titulo de la cabecera
  @Input() draggable: boolean; // Si es arrastable
  @Input() resizable: boolean; // Si es redimencionable
  @Input() modal: boolean; // Si es typo modal
  @Input() pop: boolean; // Si es typo pop
  @Input() popElementRef: ElementRef;
  @Input() btnClose = true;
  @Input() btnMinimize = false;
  @Input() minimizeNotDisplay: false;
  @Input() header = true;
  @Input() position = 'left';
  @Input() openRef: Element;
  @Input() top: string;
  @Input() height: string;
  @Output() EmitterAction: EventEmitter < any > = new EventEmitter < any > ();
  @ViewChild('draggable') el: ElementRef; // Referencial al elemento
  isOpen = false;
  isMinimized = false;
  progress = false;

  constructor(private card: ElementRef) {
    this.title = this.title ? this.title : 'Card';
  }

  ngOnInit() {
  }

  /**
   * @Description:
   */
  ngAfterViewInit() {
    if (this.draggable) {
      jQuery(this.el.nativeElement)
        .draggable({
          handle: '.t2-card-main-header',
          containment: this.modal || this.pop ? 'body' : '#layout-main-content'
        });
    }
    if (this.resizable) {
      jQuery(this.el.nativeElement)
        .resizable();
    }
  }

  /**
   * @desc Abrir y/o cerrar la modal
   */
  toggle() {
    // this.isOpen = !this.isOpen;
    return this.isOpen ? this.close() : this.open();
  }


  position_x() {
    let elem = jQuery(this.openRef).offset().left;
    elem =  parseInt(elem, 10);
    const dom = window.innerWidth - 60;
    const clientWidth = jQuery('.card-pop', this.card.nativeElement).width() - 60;
    if ((dom - elem) > clientWidth) {
      return elem;
    } else if (clientWidth < elem) {
      return `${elem - clientWidth - 60}`;
    } else {
      return (dom / 2) -  clientWidth / 2;
    }
  }

  position_y() {
    let elem = jQuery(this.openRef).offset().top;
    elem =  parseInt(elem, 10);
    const dom = window.innerHeight - 50;
    const client = jQuery('.card-pop', this.card.nativeElement).height() - 50;
    if ((dom - elem) > client) {
      return elem + 50;
    } else if (client < elem) {
      return `${elem - client - 50}`;
    } else {
      return (dom / 2) -  client / 2;
    }
  }

  /**
   * @desc Abrir la modal
   * @return Object <EventEmitter>
   */
  open(event ? ): boolean {
    this.isOpen = true;
    if (!this.modal && !this.pop) {
      this.el.nativeElement.style.display = 'block';
      this.emitter();
    } else if (this.modal) {
      this.el.nativeElement.style.top = '-200vh';
      this.el.nativeElement.style.position = 'fixed';
      setTimeout(() => {
        this.el.nativeElement.style.top = this.top ? this.top : '100px';
        this.el.nativeElement.style.position = 'fixed';
        const w_ele = this.el.nativeElement.clientWidth;
        const w_window = window.innerWidth;
        // const left = w_ele < w_window ? (w_window - w_ele) / 2 : 0;
        this.el.nativeElement.style.left = `50%`;
        this.el.nativeElement.style.transform = `translateX(-50%)`;
        this.emitter();
      }, 100);
    } else {
      this.el.nativeElement.style.position = 'fixed';
      if (!this.openRef) {
        this.el.nativeElement.style.top = event.y + 'px';
        this.el.nativeElement.style.left = event.x + 'px';
      } else {
        this.el.nativeElement.style.left = this.position_x() + 'px';
        this.el.nativeElement.style.top = this.position_y() + 'px';
      }

      // this.el.nativeElement.style.opacity = '0';
      this.emitter();

    }
    return true;
  }

  /**
   * @desc Cerrar la modal
   * @return Object <EventEmitter>
   */
  close() {
    this.isOpen = false;
    this.isMinimized = false;
    if (!this.modal && !this.pop) {
      this.el.nativeElement.style.display = 'none';
    }
    return this.emitter();
  }

  /**
   * @description minimizar la card
   */
  minimize() {
    this.isMinimized = true;
    return this.emitter();
  }

  /**
   * @description Restaurar la card
   */
  maximize() {
    this.isMinimized = false;
    return this.emitter();
  }

  /**
   * @description Emitir estado de la card
   */
  emitter() {
    return this.EmitterAction.emit({
      isOpen: this.isOpen,
      class: this.classStyle,
      isMinimized: this.isMinimized
    });
  }

  /**
   * @description Mostrar o no barra de progreso
   * @param event boolean
   */
  viewProgressBar(event: boolean) {
    this.progress = event;
  }

}
