import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { Observable } from 'rxjs';
import { constant } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class PurchasesService {

  constructor(private _global: GlobalService) { }

  get(status: boolean): Observable<any> {
    return this._global.get(`${constant.pruchase.urls.base}/status/${status}`);
  }

  getAllPurchase(): Observable<any> {
    return this._global.get(`${constant.pruchase.urls.base}`);
  }

  create(data: any): Observable<any> {
    return this._global.post(constant.pruchase.urls.base, data);
  }

  update(data, id): Observable<any> {
    return this._global
      .put(`${constant.pruchase.urls.base}/${id}`, data);
  }

  delete(pur): Observable<any> {
    return this._global
      .delete(`${constant.pruchase.urls.base}/${pur}`);
  }

  importPurchases(data: any): Observable<any> {
    return this._global.post(constant.purchases.urls.import, data);
  }


}
