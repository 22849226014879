import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { isPresent } from 'src/app/utils/helpers/helpers';

@Directive({
  selector: '[appValidatorsViewErrors]'
})
export class ValidatorsViewErrorsDirective implements AfterViewInit {

  private subscribe = false;

  @Input() control: AbstractControl;

  constructor(private element: ElementRef) { }

  buildTextErrors(errors) {
    let msg = '';
    if (isPresent(errors) && (this.control.dirty || this.control.touched)) {
      if (errors.message) {
        msg = `<p class="color-danger em-0-8 plr5 ">${errors.message}</p>`;
      } else {
        msg = `<p class="color-warning em-0-8 plr5 ">Invalid value</p>`;
      }
    }

    this.element.nativeElement.innerHTML = msg;
  }

  _each() {
    if (!this.subscribe && isPresent(this.control)) {
      this.subscribe = true;
      this.control.valueChanges
      .subscribe(() => {
        this.buildTextErrors(this.control.errors);
      });
    }
  }

  ngAfterViewInit(): void {
    this._each();
  }

}
