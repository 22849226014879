import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { Observable } from 'rxjs';
import { constant } from 'src/app/app.constants';
import {LoginInterface} from '../../public/modules/authentication/components/login/login-interface';

@Injectable({
  providedIn: 'root'
})
export class BalanceService {

  constructor(private _global: GlobalService ) { }

post(data: any): Observable<any> {

return this._global.post(constant.balance.urls.base, data);

}

get(id): Observable<any> {
  return this._global
    .get(`${constant.balance.urls.pendingBalance}/${id}`);
}

  postUpdateDeduction(data): Observable<any> {
    return this._global
      .post(constant.balance.urls.postUpdateDeduction, data);
  }
}
