import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-private-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {

  activeMenu: boolean;

  constructor(router: Router) {

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeMenu = false;
      }
    });
  }

  ngOnInit() {
  }

}
