
export class Purchase {
  id: any;
  dateOfService: any;
  receipt: any;
  amount: any;
  deductions: any;
  customer_id: any;
  customer: {
    last_name: any,
    name: any
  };



}

