import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, Renderer } from '@angular/core';
import { CardComponent } from 'src/app/utils/card/card.component';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { finalize } from 'rxjs/operators';
import { GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-utils-drop-downs-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  private grid: GridOptions;
  wait: boolean;
  customers = [];

  @Input() typeSelect: boolean;
  @Input() inputClass: string;
  @Input() inputClassCard: string;
  @Input() elemRef: ElementRef;
  @Output() areLoadedCustomers: EventEmitter<any> = new EventEmitter<any>();
  @Output() itSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() itClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() itOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() ready: EventEmitter<boolean> = new EventEmitter<boolean>();


  @ViewChild('card') card: CardComponent;

  columnDefs = [
    {headerName: 'Customer', field: 'employee_id', width: 80},
    {headerName: 'Last Name', field: 'last_name', width: 80},
    {headerName: 'Name', field: 'name', width: 80},
    {headerName: 'Hospital', field: 'hospital', width: 80},
    {headerName: 'email', field: 'email', width: 80},
    {headerName: 'Phone', field: 'phone', width: 80},
    {headerName: 'Alt Pone', field: 'cell_phone', width: 80},
    {headerName: 'Address', field: 'address', width: 80},
    {headerName: 'State', field: 'state', width: 80},
    {headerName: 'City', field: 'city', width: 80},
    {headerName: 'Apt', field: 'apt', width: 80},
    {headerName: 'Zipcode', field: 'zipcode', width: 80},
  ];


  constructor(
    private elementRef: ElementRef,
    private _customers: CustomersService,
    private render: Renderer
  ) {
    this.grid = < GridOptions > {};

    this.render.listen(this.elementRef.nativeElement, 'mouseout', (event) => {
      // console.log('focus', event);
    });
  }

  ngOnInit() {
    this.getAllCustomers();
    if (this.typeSelect) {
      this.card.close();
    }
  }

  getAllCustomers() {
    this.wait = true;
    return this._customers
      .get()
      .pipe(
        finalize(() => {
          this.wait = false;
          this.ready.emit(true);
        })
      )
      .subscribe(
        (success) => {
          this.customers = success.data;
          this.areLoadedCustomers.emit(success.data);
          if (this.grid) {
            if (this.grid.api) {
              this.grid.api.setRowData(success.data);
              this.grid.api.sizeColumnsToFit();
            }
          }
        }, (error) => {
          this.areLoadedCustomers.emit([]);
          console.log('getAllCustomers error', error);
        }
      );
  }

  searchCustomer(id: number) {
    return this.customers.filter((data) => {
      if (data.id === id) {
        return data;
      }
    })[0];
  }

  setSelected(params) {
    this.itSelected.emit(params.data);
  }

  open() {
    this.card.open();
    this.itOpen.emit(true);
  }

  close() {
    this.card.close();
    this.itClose.emit(true);
  }

  onEmitterAction(event) {
    if (!event.isOpen) {
      this.itClose.emit(true);
    }
  }

}
