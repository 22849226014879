import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import Swal from 'sweetalert2';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { emoticons } from './app.emoticons';
import { AuthenticationModule } from './public/modules/authentication/authentication.module';
import { InterceptService } from './services/intercept.service';
import { TemplateModule } from './private/template/template.module';
import { HomeModule } from './private/modules/home/home.module';
import { CustomersModule } from './private/modules/customers/customers.module';
import { DataProcessingModule } from './private/modules/data-processing/data-processing.module';
import { HospitalsModule } from './private/modules/hospitals/hospitals.module';
import { PurchasesModule } from './private/modules/purchases/purchases.module';
import {TransactionsModule} from './private/modules/transactions/transactions.module';

import { LicenseManager} from 'ag-grid-enterprise/main';
import { AgGridModule } from 'ag-grid-angular';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';
import { UsersModule } from './private/modules/users/users.module';

import { NgxMaskModule } from 'ngx-mask';

window['emoticons'] = emoticons;

LicenseManager.setLicenseKey('');
LicenseManager['outputMessage'] = function (data1, data2) {
  // console.log('outputMessage', data1, data2);
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      closeButton: true,
      enableHtml: true,
    }),
    AgGridModule.withComponents([]),
    SweetAlert2Module.forRoot(),
    NgxMyDatePickerModule.forRoot(),
    // Mudules
    AuthenticationModule,
    HomeModule,
    CustomersModule,
    DataProcessingModule,
    HospitalsModule,
    PurchasesModule,
    TransactionsModule,
    UsersModule,
    TemplateModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
