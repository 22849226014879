import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { constant } from 'src/app/app.constants';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor (
    private _router: Router,
    private _toastr: ToastrService,
    private _auth: AuthenticationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      try {
        if (constant.auth.getToken()) {
          this._auth.verifyToken()
            .subscribe((success) => {
            }, (err) => {
              console.log('verifyToken Error', err);
            });
          return true;
        }
      } catch (error) {
        console.log('AuthGuard', error);
      }
      this._toastr.warning('You do not have permissions for this module', 'Access denied');
      this._router.navigateByUrl(constant.views.login.base);
  }
}
