import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-private-template-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss']
})
export class AsideComponent implements OnInit {

  constructor() { }


  ngOnInit() {}

}
