import {ValidatorFn, AbstractControl } from '@angular/forms';
import { isPresent, isMultiParams } from 'src/app/utils/helpers/helpers';

export const CustomValidateEquals = (options: any): ValidatorFn => {
  let subscribe = false;
  let sensitive;
  const message = 'this value must be equal to';
  let formControlName;

  return (control: AbstractControl): any => {
    if (typeof options === 'string') {

      const params = isMultiParams(options);
      if (params) {
        formControlName = params[0];
        sensitive = JSON.parse(params[1]);
      } else {
        formControlName = options;
      }
    }

    if (typeof options === 'object') {
      formControlName = options.formControlName;
      sensitive = options.sensitive ? true : false;
    }

    if (!subscribe && isPresent(control.parent)) {
      if (isPresent(control.parent.controls)) {
        if (isPresent(control.parent.controls[formControlName])) {
          subscribe = true;
          control.parent.controls[formControlName].valueChanges
          .subscribe(() => {
          control.updateValueAndValidity();
        });
        }
      }
    }

    if (!control.value) {
      return null;
    }

    const val = control.parent.controls[formControlName].value;

    if (!sensitive) {
      return control.value.toLowerCase() === val.toLowerCase() ? null : {
        invalidEquals: true,
        message: `${message} '${val}'`
      };
    }

    return control.value === val ? null : {
      invalidEquals: true,
      message: `${message} '${val}'`
    };

  };
};
