import { Component, OnInit, ViewChild } from '@angular/core';
import { PurchasesService } from 'src/app/services/purchases/purchases.service';
import { Purchase } from './purchase';
import { ToastrService } from 'ngx-toastr';
import { CreateComponent } from './components/create/create.component';
import { BalanceService } from 'src/app/services/balance/balance.service';
import { finalize } from 'rxjs/operators';
import { UpdateComponent } from './components/update/update.component';
import {CardComponent} from '../../../utils/card/card.component';
import Swal from 'sweetalert2';

declare const $: any;

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.scss']
})
export class PurchasesComponent implements OnInit {

  onSend: boolean;
  Transtypeid: any;
  purc = [];
  clipurc: any;
  constSend = [];
  idPur = [];
  allSelected: boolean;
  actSend = true;

  @ViewChild('componentCreatePurchases') componentCreate: CreateComponent;
  @ViewChild('componentUpdatePurchases') componentUpdate: UpdateComponent;
  @ViewChild('cardUpdate') cardUpdate: CardComponent;

  cardEmitter = {
    'isOpen': false
  };
  private allPurchases = [];

  constructor(private _purchases: PurchasesService, private _toast: ToastrService, private _balance: BalanceService) {}

  ngOnInit() {
    this.getPurchases();
    this.getAllPurchase();
  }

  getPurchases() {
    this._purchases.get(true).subscribe((successfull) => {
      console.log('getPurchases', successfull);
      this.purc = successfull;
    }, (error) => {
      console.log(error);
    });
  }

  cliPurchase(pur: any) {
    console.log('cliPurchase', pur);
    this.clipurc = pur;
  }

  deletePurchase(data) {
    this._purchases
    .delete(data.id)
    .subscribe(
      (successfull) => {
        console.log('deletePurchase', successfull);
        this.purc.splice(this.purc.indexOf(data), 1);
        this.getPurchases();
        Swal({
          title: 'Purchase Removal Successfully',
          type: 'success',
          confirmButtonColor: '#3085d6 ',
          confirmButtonText: 'Close'
        });
      },
      (error) => {
        this._toast.error(error.message);
        console.log('deletePurchase', error);
      });
  }

  filterForActiveSend() {
    const actives = this.purc.filter((row) => {
      if (row.checked === true) {
        return row;
      }
    })
    .map((row) => {
      if (row.checked === true) {
          return row.id;
      }
    });
    return actives;
  }

  send() {
    const data = this.filterForActiveSend();
    if (data.length) {
      this.onSend = true;
      this._balance
      .post(data)
      .pipe(
        finalize(() => {
          this.onSend = false;
        })
      )
      .subscribe((success) => {
        console.log('send success', success);
          this.getPurchases();
          Swal({
            title: 'Successful Post',
            type: 'success',
            confirmButtonColor: '#3085d6 ',
            confirmButtonText: 'Close'
          });
          this.getAllPurchase();
      },
      (err) => {
        console.log('send err', err);
      });
    }
  }

  toggleSelectAll(param: boolean) {
    this.purc.forEach((element) => {
      element.checked = param;
    });
    this.allSelected = param;

  }

  allSelectedForSend() {
    const selected = this.purc.filter((element) => {
      if (element.checked) {
        return element;

      }
    });

    return selected.length === this.purc.length;
  }

  sumTotal() {
    let total = 0;
    this.purc.forEach(element => {
      total = total + parseFloat(element.amount);
    });

    return total;
  }


  printData() {
    window.print();
  }

  getAllPurchase() {
    this._purchases.getAllPurchase()
      .subscribe( resp => this.allPurchases = resp.data);
  }



}
