import { Injectable } from '@angular/core';
import { GlobalService } from '../global.service';
import { Observable } from 'rxjs';
import { constant } from 'src/app/app.constants';
import { UsersInterface } from 'src/app/private/modules/users/users-interface';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private _global: GlobalService) { }

  create(user: UsersInterface): Observable<any> {
    user.password = new Date().toString();
    return this._global.post(constant.users.urls.base, user);
  }

  update(data: any, user: number): Observable<any> {
    return this._global.put(`${constant.users.urls.profiles}/${user}`, data);
  }

  get(active?: boolean): Observable<any> {
    return this._global.get(`${constant.users.urls.actives}/${active}`);
  }

  delete(id: number): Observable<any> {
    return this._global
      .delete(`${constant.users.urls.base}/${id}`);
  }


}
