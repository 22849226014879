import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { PurchasesService } from 'src/app/services/purchases/purchases.service';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { TransactionService } from 'src/app/services/transactions/transaction.service';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { ToastrService } from 'ngx-toastr';
import { IMyDateModel } from 'mydatepicker';
import { INgxMyDpOptions } from 'ngx-mydatepicker';
import { finalize } from 'rxjs/operators';
import { CustomersComponent } from 'src/app/utils/drop-downds/components/customers/customers.component';
import Swal from 'sweetalert2';

declare const moment: any;

@Component({
  selector: 'app-private-purchases-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})

export class CreateComponent implements OnInit, OnChanges {

  public form: FormGroup;
  public trans: [];
  public selectCust: any;
  allCustomers = [];
  onUpdate = false;
  onSave: boolean;
  idPur: number;
  onSearchCustomer: boolean;
  employee = [];
  allNewPruchases = [];
  currentPurchases = 0;
  onCurrentEdit: any;
  defaultDeductions = '3';
  currentPage: any;
  itemsPerPage = 10;

  myOptions: INgxMyDpOptions = {
    dateFormat: 'mm-dd-yyyy',
  };

  @Output() cancelEmitter: EventEmitter < boolean > = new EventEmitter < boolean > ();
  @Output() saveEmitter: EventEmitter < any > = new EventEmitter < any > ();
  @ViewChild('dropDown') dropDown: CustomersComponent;
  @ViewChild('focusInputIndex') focusInputIndex: ElementRef;
  readyCustomers: boolean;
  focus: boolean;
  @Input() cardEmitter;
  @Input() allPurchases = [];

  constructor(
    private _purchase: PurchasesService,
    private fr: FormBuilder,
    private _transaction: TransactionService,
    private _customers: CustomersService,
    private _toastr: ToastrService
  ) {
    this.builderForm();
  }

  ngOnInit() {
    this.getTransaction();
    this.valueChanges();
  }

  builderForm() {
    this.form = this.fr.group({
      dateOfService: ['', [CustomValidators.required]],
      amount: ['', [CustomValidators.required]],
      deductions: [this.defaultDeductions, [CustomValidators.required]],
      customer_id: ['', [CustomValidators.required]],
      receipt: ['', [CustomValidators.required]],
      transaction_type_id: [1],
      customer: []
    });
    this.form.disable();
  }

  getTransaction() {
    this._transaction.getType().subscribe(
      (successfull) => {
        console.log(successfull);
        this.trans = successfull.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }


  onSubmit(value) {
    this.onSave = true;
    this.form.disable();
    const data = this.form.getRawValue();
    data.transaction_type_id = 1;
    data.dateOfService = data.dateOfService.formatted;
    if (this.onUpdate === false) {
      this._purchase
        .create(data)
        .pipe(
          finalize(() => {
            this.onSave = false;
            this.form.enable();
          })
        )
        .subscribe(
          (successfull) => {
            console.log(successfull);
            this._toastr.success('Success Ok');
            this.resetForm();
            this.saveEmitter.emit({
              save: true,
              data: successfull
            });
          },
          (error) => {
            this.saveEmitter.emit({
              save: false,
              data: error
            });
            console.log(error);
          });
    }
    if (this.onUpdate === true) {

      this._purchase.update(data, this.idPur).pipe(
          finalize(() => {
            this.onSave = false;
            this.form.enable();
          })
        )
        .subscribe((successfull) => {
          console.log(successfull);
          this._toastr.success('Success Ok');
          this.resetForm();
          this.saveEmitter.emit({
            save: true,
            data: successfull
          });
        }, (error) => {
          this.saveEmitter.emit({
            save: false,
            data: error
          });
        });
    }


  }

  create(purchase: any) {
    purchase.onSave = true;

    this._purchase
    .create({
      amount: purchase.amount,
      customer_id: purchase.customer_id,
      dateOfService: purchase.dateOfService.formatted,
      deductions: purchase.deductions,
      employee_id: purchase.employee_id,
      receipt: purchase.receipt,
      transaction_type_id: purchase.transaction_type_id,
    })
    .pipe(
      finalize(() => {
        this.onSave = false;
        purchase.onSave = false;
      })
    )
    .subscribe(
      (successfull) => {
        purchase.save = true;
        console.log(successfull);
        this.saveEmitter.emit({
          save: true,
          data: successfull
        });
      },
      (error) => {
        purchase.save = false;
        purchase.error = error;
        this.saveEmitter.emit({
          save: false,
          data: error
        });
        console.log(error);
      });
  }

  onSubmitPurchases(event: any) {
    console.log('onSubmitPurchases', this.allNewPruchases);
    this.onCurrentEdit = undefined;
    this.selectCust = {
      employee_id: '',
      last_name: '',
      name: ''
    };
    this.resetForm();
    this.allNewPruchases
    .forEach((item) => {
      if (!item.save) {
        this.create(item);
      }
    });
  }

  selectCustid(event) {
    this.selectCust = event;
    this.form.get('customer_id').setValue(event.id);
    this.getCustomer(this.selectCust.employee_id);
    this.form.enable();
    this.focusAddBtn();
  }

  getCustomer(employee: number) {
    this._customers
      .getDetail(employee)
      .subscribe(
        success => {
          console.log('getCustomer success', success);
          this.form.get('customer').setValue(success.data);
        },
        err => {
          console.log('getCustomer err', err);
        }
      );
  }

  // optional data changed callback
  onDateChanged(event: IMyDateModel): void {
    // data selected
  }

  cancel() {
    this.cancelEmitter.emit(true);
  }

  searchCustomer(value: number) {
    this.onSearchCustomer = true;
    let data: any;
    data = this.allCustomers.filter((row) => {
      if (row.employee_id === value) {
        return row;
      }
    });
    if (data.length) {
      this.selectCustid(data[0]);
      this.form.enable();
    } else {
      this.form.disable();
      this.selectCust = {
        employee_id:  value
      };
      this.selectCust.employee_id = '';
      this.form.get('customer_id').setValue('');
    }
    this.focusAddBtn();
    this.onSearchCustomer = false;
  }

  resetForm() {
    this.form.reset();
    this.form.get('deductions').setValue(this.defaultDeductions);
    this.form.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cardEmitter']) {
      if (this.cardEmitter.isOpen) {
        this.focusAddBtn();
      }
    }

    this.onUpdate = false; /*se le da el valor false cuando se activa ngOnChanges*/
    this.resetForm();
    this.idPur = undefined;
    if (changes['cliPurchase']) { /* si hay cambios en cliPurchase cuando se activa ngOnChanges*/
      if (changes['cliPurchase'].currentValue) {
        this.onUpdate = true; /*se le da el valor true cuando hay canvios en cliHospital*/
        this.idPur = changes['cliPurchase'].currentValue.id; /*se asigna el id que trae cliPurchase*/

        const data = new Date(moment(changes['cliPurchase'].currentValue.dateOfService).format());
        /* el campo dateOfService del data Picker espera un objeto,  para esto se utiliza moment,
        este combierte el string que trae cliPurchase en objeto con zona Horaria y se pasa al data Picker*/

        this.form.setValue({
          dateOfService: {
            date: {
              year: data.getFullYear(),
              month: data.getMonth() + 1,
              day: data.getDate()
            }
          },
          amount: changes['cliPurchase'].currentValue.amount,
          deductions: changes['cliPurchase'].currentValue.deductions,
          receipt: changes['cliPurchase'].currentValue.receipt,
          customer_id: changes['cliPurchase'].currentValue.customer_id,
          transaction_type_id: '1',
        });
      }
    }
  }

  onLoadCustumers(event) {
    console.log('onLoadCustumers', event);
    this.allCustomers = event;
  }

  addPurchase() {
    const data = this.form.getRawValue();
    data.employee_id = this.selectCust.employee_id;
    data.transaction_type_id = 1;
    this.allNewPruchases.push(data);
    this.selectCust = {
      employee_id: '',
      last_name: '',
      name: ''
    };
    this.resetForm();

    this.currentPage = this.allNewPruchases.length / this.itemsPerPage;
    this.currentPage = Math.ceil(this.currentPage);
    if (this.currentPage <= 0 ) {
      this.currentPage = 1;
    }
  }

  deletePurchase(item: any) {
    this.allNewPruchases.splice(
      this.allNewPruchases.indexOf(item), 1
    );
  }

  updateCurrentEdit() {
    const data = this.form.getRawValue();
    data.employee_id = this.selectCust.employee_id;
    const index = this.allNewPruchases.indexOf(this.onCurrentEdit);
    if (!this.allNewPruchases[index].onSave) {
      if (!this.allNewPruchases[index].save) {
        this.allNewPruchases[index] = data;
        this.selectCust = {
          employee_id: '',
          last_name: '',
          name: ''
        };
        this.resetForm();
        this.onCurrentEdit = undefined;
      } else {
        this._toastr.error('This record can not be updated, it has already been sent.');
      }
    } else {
      this._toastr.error('This record can not be updated, it has already been sent.');
    }
  }

  editPurchase(item: any) {
    this.onCurrentEdit = item;
    this.resetForm();
    this.form.setValue({
      dateOfService: item.dateOfService,
      amount: item.amount,
      deductions: item.deductions,
      customer_id: item.customer_id,
      receipt: item.receipt,
      transaction_type_id: item.transaction_type_id,
      customer: item.customer
    });

    this.searchCustomer(item.employee_id);

  }

  focusAddBtn() {
    this.focusInputIndex.nativeElement.focus();
  }

  getIndex(item: any) {
    return this.allNewPruchases.indexOf(item) + 1;
  }

  private valueChanges() {
    this.form.get('receipt').valueChanges.subscribe(val => {
      if (val !== null) {
        if (this.validateReceipt(false, val)) {
          this.validateReceipt(true, val);
        }
      }
    });
  }

  validateReceipt(msj, value?) {
    const receipt = value ? value : this.form.get('receipt').value;
    msj ? this.swal('warning', null, 'There is already a purchase with this receipt') : null;
    return !!this.allPurchases.filter( item => item.receipt == receipt).length;
  }

  private swal(type: string, resp?: string, msj?: string) {
    if (type == 'warning') {
      Swal({
        position: 'top-end',
        title: msj ? msj : resp,
        type: 'warning',
        showConfirmButton: false,
        timer: 3000
      });
    }
  }

}
