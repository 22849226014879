import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidatorsViewErrorsDirective } from './validators-view-errors.directive';

@NgModule({
  declarations: [
    ValidatorsViewErrorsDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [ValidatorsViewErrorsDirective]
})
export class ValidatorsViewErrorsModule { }
