import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HospitalsRoutingModule } from './hospitals-routing.module';
import { HospitalsComponent } from './hospitals.component';
import { CreateComponent } from './components/create/create.component';
import { UpdateComponent } from './components/update/update.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HospitalsService } from 'src/app/services/hospitals/hospitals.service';
import { CardModule } from 'src/app/utils/card/card.module';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { FilterPipeModule } from 'ngx-filter-pipe';

@NgModule({
  declarations: [HospitalsComponent, CreateComponent, UpdateComponent],
  imports: [
    CommonModule,
    HospitalsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    SweetAlert2Module,
    FilterPipeModule
  ],
  providers: [
    HospitalsService
  ]
})
export class HospitalsModule { }
