import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from 'src/app/services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { CardComponent } from 'src/app/utils/card/card.component';

declare const faker: any;

@Component({
  selector: 'app-private-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users = [];
  userSelected: any;

  @ViewChild('usersCreate') cardUsersCreate: CardComponent;

  constructor(
    private _users: UsersService,
    private _toastr: ToastrService
  ) { }

  ngOnInit() {
    this.getAllUsers();
  }

  getAllUsers() {
    return this._users
    .get(true)
    .subscribe(
      (success) => {
        console.log('getAllUsers success', success);
        this.users = success.data;
      },
      (error) => {
        this._toastr.error(error.message);
        console.log('getAllUsers error', error);

      });
  }

  onSaveUser(event: any) {
    this.users.push(event.data);
  }

  deleteUser(user: any) {
    return this._users
      .delete(user.id)
      .subscribe(
        (success) => {
          this._toastr.success(success.message);
          this.users.splice(this.users.indexOf(user), 1);
        },
        (error) => {
          this._toastr.error(error.message);
          console.log('success deleteUser', error);
        });
  }

  selectToEdit(user: any) {
    this.userSelected = user;
    this.cardUsersCreate.open();
  }

}
