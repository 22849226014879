import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { INgxMyDpOptions, IMyDateModel, NgxMyDatePickerDirective } from 'ngx-mydatepicker';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HospitalsService } from 'src/app/services/hospitals/hospitals.service';
import { isPresent } from 'src/app/utils/helpers/helpers';
import swal from 'sweetalert2';

@Component({
  selector: 'app-private-customers-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, OnChanges {

  public form: FormGroup;
  wait: boolean;
  hospitals = [];

  @Input() isUpdate: boolean;
  @Input() customer: any;
  @Input() btnClose: boolean;

  @Output() emitterAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitterClose: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(
    private fb: FormBuilder,
    private _toastr: ToastrService,
    private _customers: CustomersService,
    private _hospitals: HospitalsService
  ) {
    this.builderForm();
  }

  ngOnInit() {
    this.getHospitals();
  }

  builderForm() {
    this.form = this.fb.group({
      employee_id: ['', [CustomValidators.required]],
      hospital: ['', [CustomValidators.required]],
      name: ['', [CustomValidators.required, CustomValidators.onlyText]],
      last_name: ['', [CustomValidators.required, CustomValidators.onlyText]],
      address: [''],
      apt: [''],
      city: [''],
      state: [''],
      zipcode: ['', [CustomValidators.onlyNumbers, CustomValidators.minLength(2), CustomValidators.maxLength(6)]],
      phone: [''],
      cell_phone: [''],
      email: ['', [CustomValidators.email]],
    });
  }

  setValuesFrom(data) {
    try {
      Object.keys(this.form.controls).forEach(key => {
        this.form.get(key).setValue(data[key]);
      });
    } catch (error) {
      this._toastr.error('An error occurred while loading the data to update', 'setValuesFrom');
      console.error(error);
    }

  }

  /**
   * @description Solicitud de login
   */
  onSubmit(values) {
    this.isUpdate ? this.update(values) : this.create(values);
  }

  create(values) {
    this.wait = true;
    this.form.disable();
    return this._customers
      .create(values)
      .pipe(
        finalize(() => {
          this.wait = false;
          this.form.enable();
        })
      )
      .subscribe(
        (success) => {
          this._toastr.success(success.message);
          this.resetForm();
        }, (error) => {
          console.error('onSubmit error', error);
          swal({
            type: 'error',
            title: 'Oops...',
            text: (error.message)
          });
        }
      );
  }

  update(values) {
    this.wait = true;
    this.form.disable();
    this._customers
      .update(values, this.customer.id)
      .pipe(
        finalize(() => {
          this.wait = false;
          this.form.enable();
        })
      )
      .subscribe(
        (success) => {
          this._toastr.success(success.message);
          this.emitter(true, values);
        }, (error) => {
          console.error('update error', error);
          this.emitter(false, values);
          this._toastr.error(error.message);
        }
      );
  }

  /**
   * @description Cargar hospitales
   */
  getHospitals() {
    return this._hospitals
      .get()
      .subscribe(
        (success) => {
          this.hospitals = success.data;
        }, (error) => {
          console.error('getHospitals error', error);
          this._toastr.error(error.message);
        }
      );
  }

  resetForm() {
    this.form.reset();
    this.form.updateValueAndValidity();
  }

  emitter(event, data) {
    return this.emitterAction.emit({
      isUpdate: this.isUpdate,
      save: event,
      data: data
    });
  }

  clickOnClose() {
    this.emitterClose.emit(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isPresent(changes['customer'])) {
      if (isPresent(changes['customer'].currentValue)) {
        this.setValuesFrom(changes['customer'].currentValue);
      }
    }
  }

}
