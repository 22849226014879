import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMyDatePickerModule } from 'ngx-mydatepicker';

import { CustomersRoutingModule } from './customers-routing.module';
import { CreateComponent } from './components/create/create.component';
import { CustomersComponent } from './customers.component';
import { ValidatorsViewErrorsModule } from 'src/app/directives/validators-view-errors/validators-view-errors.module';
import { CustomersService } from 'src/app/services/customers/customers.service';
import { ProgressBarModule } from 'src/app/utils/progress-bar/progress-bar.module';
import { DetailComponent } from './components/detail/detail.component';
import { CardModule } from 'src/app/utils/card/card.module';
import { ToImportModule } from 'src/app/utils/to-import/to-import.module';
import { ImportComponent } from './components/import/import.component';

@NgModule({
  declarations: [CreateComponent, CustomersComponent, DetailComponent, ImportComponent],
  imports: [
    CommonModule,
    CustomersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMyDatePickerModule.forRoot(),
    ValidatorsViewErrorsModule,
    ProgressBarModule,
    CardModule,
    ToImportModule
  ],
  exports: [CreateComponent, CustomersComponent, DetailComponent, ImportComponent],
  providers: [
    CustomersService
  ]
})
export class CustomersModule { }
