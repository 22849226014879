import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TemplateComponent } from '../../template/template.component';
import { AuthGuard } from '../../guards/auth/auth.guard';
import { PurchasesComponent } from './purchases.component';

const routes: Routes = [{
  path: 'private/purchases',
  component: TemplateComponent,
  canActivate: [AuthGuard],
  children: [{
    path: '',
    component: PurchasesComponent
  }]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PurchasesRoutingModule { }
